<template>
  <div>
    <table class=''>
      <thead>
        <tr class='border border-b text-sm text-center'>
          <th class='py-3 px-2 border whitespace-no-wrap bg-gray-200' colspan='2'>참여계좌 합계</th>
        </tr>
        <tr class='border border-b text-sm text-center'>
          <th class='w-32 border-l text-right py-3 px-2 border whitespace-no-wrap bg-gray-200'>청약수량</th>
          <th class='w-32 border-l text-right py-3 px-2 border whitespace-no-wrap bg-gray-200'>청약금액</th>
        </tr>
      </thead>
      <tbody>
        <tr class='border border-b text-sm text-center'>
          <td class='w-32 border-l text-right py-3 px-2 border whitespace-no-wrap'>{{ numberStyle(totalAllocatedQuantity) }}</td>
          <td class='w-32 border-l text-right py-3 px-2 border whitespace-no-wrap'>{{ numberStyle(totalAllocatedAmount) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState }  from 'vuex'

export default {
  name: 'IpoDocsWaveSummary',
  data () {
    return {
    }
  },
  computed: {
    ...mapState('ipos', [
      'ipoSummaries',
    ]),
    ...mapState('brokers', [
      'brokers',
    ]),
    totalAllocatedQuantity () {
      return this.ipoSummaries.filter(summary => summary.account_type !== '고유').reduce((sum, summary) => sum + summary.allocated_quantity, 0)
    },
    totalAllocatedAmount () {
      return this.ipoSummaries.reduce((sum, summary) => {
        const allocatedPrice = summary.allocated_price + (summary.allocated_price * summary.subs_fee_rate / 100)
        return sum + (summary.allocated_quantity * allocatedPrice)
      }, 0)
    },
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
  },
}
</script>
